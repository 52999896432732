<div class="main flex-row items-center justify-center">
  <mat-icon
    class="closeIcon"
    fontIcon="close"
    (click)="closepopup()"
  ></mat-icon>

  <form class="login-form" [formGroup]="form" (ngSubmit)="login()">
    <h1>Accedi</h1>

    <mat-form-field
      class="mb-3"
      appearance="fill"
      color="primary"
      hideRequiredMarker="true"
    >
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email" formControlName="email" />
      <mat-icon matPrefix>email</mat-icon>
      <mat-hint
        align="start"
        class="text-red-600"
        *ngIf="form.hasError('resetPsw')"
      >
        Devi inserire la mail per il recupero della password
      </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill" color="primary" hideRequiredMarker="true">
      <mat-label>Password</mat-label>
      <input
        matInput
        type="{{ hide ? 'password' : 'text' }}"
        formControlName="password"
        placeholder="Password"
      />
      <mat-icon matPrefix>lock</mat-icon>
      <mat-icon (click)="hide = !hide" matSuffix>
        {{ hide ? "visibility_off" : "visibility" }}
      </mat-icon>
    </mat-form-field>

    <div *ngIf="showResendEmail" (click)="ResendEmail()" class="forgot text-white m-auto cursor-pointer underline underline-offset-2">
      invia di nuovo email di conferma
    </div>

    <mat-checkbox formControlName="rememberMe" color="warn" class="pb-4"
      ><p class="text-white userAgreement">Rimani connesso</p></mat-checkbox
    >

    <div (click)="resetPsw()" class="forgot text-white m-auto cursor-pointer">
      Hai dimenticato la
      <span class="underline underline-offset-1">password</span> ?
    </div>

    <button
      mat-raised-button
      class="m-auto w-2/3"
      color="orange"
      type="submit"
      [disabled]="!form.valid"
    >
      Accedi
    </button>
  </form>
</div>
