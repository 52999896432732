import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { aU } from '@fullcalendar/core/internal-common';
import { filter, map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  isPlatFormBrowser: boolean;

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isPlatFormBrowser = isPlatformBrowser(platformId);
  }

  updateMetaInfo(content: string, author: string) {
    if (content != null && content != '')
      this.meta.updateTag({ name: 'description', content: content });

    if (author != null && author != '')
      this.meta.updateTag({ name: 'author', content: author });
  }

  updateTitle(title?: string) {
    if (!title) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data)
        )
        .subscribe((event) => {
          this.titleService.setTitle(
            event['title'] != '' ? 'SMDRIFT - ' + event['title'] : 'SMDRIFT'
          );
          this.updateMetaInfo(event['description'], event['author']);
        });
    } else {
      this.titleService.setTitle(
        title != '' ? 'SMDRIFT - ' + title : 'SMDRIFT'
      );
    }
  }

  cleanActive() {
    if (!this.isPlatFormBrowser) return;

    let menu_btn = document.querySelector<HTMLElement>('.hamburger');
    let mobile_menu = document.querySelector<HTMLElement>('.mobile-nav');
    let body = document.querySelector<HTMLElement>('body');
    let loginModal = document.querySelector<HTMLElement>('#login-form');

    menu_btn?.classList.remove('is-active', 'fixed');
    mobile_menu?.classList.remove('is-active');
    body?.classList.remove('noscroll');

    if (loginModal != null) loginModal.style.visibility = 'hidden';
  }
}
